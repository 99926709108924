@import "variables";
//覆盖源生框架的样式都放在这里


.ant-layout-footer{
  background: #1C3544;
}

.ant-layout-header {
  color: rgba(0, 0, 0, 0.85);
  background: #00000063;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  opacity: 1;
  transition: opacity 1s;
}