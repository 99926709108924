.flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex{
  flex:1
}

.space-between{
  justify-content: space-between;
}

.space-around{
  justify-content: space-around;
}

.space-evenly{
  justify-content: space-evenly;
}

