@media only screen and (min-width: 1024px) {
  .auto-content{
    margin: 0 10%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 1024px) {
  .auto-content{
    margin: 0 5%;
  }
}

@media only screen and (max-width: 400px) {
  .auto-content{
    margin: 0 5%;
  }
}