@import "variables";
@import "utils-text";
@import "utils-color";
@import "utils-size";
@import "utils-border";
@import "utils-spacing";
@import "cover";
@import "flex";
@import "auto";


.admin-login {
  background: #F9FBFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .admin-login-body {
    border-radius: 8px;
    background: #fff;
    box-shadow: 5px 5px 10px #cccccc;
    padding: 3rem 3rem 6rem;
    width: 28rem;

    .admin-logo {
      width: 60%;
      max-width: 12rem;
      height: auto;
      margin: 0 auto;
      display: block;
    }

    .admin-title {
      text-align: center;
      font-size: 24px;
      letter-spacing: .1rem;
    }

  }
}

.admin-wrapper {

  overflow-y: hidden;

  .ant-layout-sider {
    background: @dark-theme-color;
    height: 100vh;

    .admin-sider {
      .admin-logo {
        width: 140px;
        height: auto;
        display: block;
        margin: 2rem auto;
      }

      .ant-menu.ant-menu-dark {
        background: none;
      }

    }
  }

  .ant-layout-header {
    height: 40px;
    background: #ffffff;
    line-height: 40px;
    position: initial;
    padding: 0 1rem;
    border-bottom: 1px solid #ebebeb;

    .ant-breadcrumb {
      line-height: inherit;
    }
  }


  .admin-home {
    background: #ffffff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .inner {

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .logo-img {
        width: 180px;
        height: auto;
        display: block;
      }

      .title {
        font-size: 1.5rem;
      }

    }


  }

  .main-wrap {
    height: calc(100% - 40px);
  }

}

.admin-content {
  //background: #fff;
  //padding: 1rem;

  .admin-head {
    background: #ffffff;
    padding: 1rem 2rem;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .admin-body {
    margin: .8rem .8rem 0;
    padding: .8rem .8rem 0;
    background: #ffffff;
  }


}

.bf-container {
  border: 1px solid #dedede;
}

.admin-sub {
  .admin-body {
    padding: 0.8rem;
    background: #ffffff;
    height: calc(100vh - 90px);
    overflow-y: scroll;
  }

  .admin-footer {
    background: #ffffff;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #ebebeb;
    height: 50px;
    line-height: 50px;
    padding-right: 180px;
  }
}