.text {
  font-size: 1rem;
}
.text-2x {
  font-size: 2rem;
}
.text-3x {
  font-size: 3rem;
}
.text-4x {
  font-size: 4rem;
}
.text-muted {
  opacity: 0.6;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-right .ant-input-number-input {
  text-align: right;
}
.input-number-text-right .ant-input-number-input {
  text-align: right;
}
.text-danger,
.text-danger-hover a:hover {
  color: #f44455 !important;
}
.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important;
}
.text-info,
.text-info-hover a:hover {
  color: #27B8D0 !important;
  cursor: pointer;
}
.text-success,
.text-success-hover a:hover {
  color: #6cc788 !important;
}
.text-blue,
.text-blue-hover a:hover {
  color: #27B8D0 !important;
}
.dark-white {
  color: #2e3e4e;
  background-color: #ffffff;
}
.min-black {
  color: #2a2b3c;
  background-color: rgba(0, 0, 0, 0.065);
}
.black {
  color: #2a2b3c;
  background-color: #2a2b3c;
}
.dark {
  color: #2e3e4e;
  background-color: #2e3e4e;
}
.grey {
  color: #424242;
  background-color: #424242;
}
.primary {
  color: #27B8D0;
  background-color: #27B8D0;
}
.info {
  color: #6887ff;
  background-color: #6887ff;
}
.warn {
  color: #fcc100;
  background-color: #fcc100;
}
.danger {
  color: #f44455;
  background-color: #f44455;
}
.theme-bg-color {
  background: #27B8D0;
}
.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}
.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}
.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}
.w-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}
.b-a {
  border: 1px solid rgba(120, 130, 140, 0.13);
}
.pt-s {
  padding-top: 0.5rem !important;
}
.pt-m {
  padding-top: 1rem !important;
}
.pt-l {
  padding-top: 2rem !important;
}
.pb-s {
  padding-bottom: 0.5rem !important;
}
.pb-m {
  padding-bottom: 1rem !important;
}
.pb-l {
  padding-bottom: 2rem !important;
}
.pl-s {
  padding-left: 0.5rem !important;
}
.pl-m {
  padding-left: 1rem !important;
}
.pl-l {
  padding-left: 2rem !important;
}
.pr-s {
  padding-right: 0.5rem !important;
}
.pr-m {
  padding-right: 1rem !important;
}
.pr-l {
  padding-right: 2rem !important;
}
.pa-s {
  padding: 0.5rem !important;
}
.pa-m {
  padding: 1rem !important;
}
.pa-l {
  padding: 2rem !important;
}
.mr-s {
  margin-right: 0.5rem !important;
}
.mr-m {
  margin-right: 1rem !important;
}
.mr-l {
  margin-right: 2rem !important;
}
.mt-s {
  margin-top: 0.5rem !important;
}
.mt-m {
  margin-top: 1rem !important;
}
.mt-l {
  margin-top: 2rem !important;
}
.ml-s {
  margin-left: 0.5rem !important;
}
.ml-m {
  margin-left: 1.2rem !important;
}
.ml-l {
  margin-left: 1.7rem !important;
}
.mb-s {
  margin-bottom: 0.5rem !important;
}
.mb-m {
  margin-bottom: 1rem !important;
}
.mb-l {
  margin-bottom: 2rem !important;
}
.mlr-s {
  padding-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mlr-m {
  padding-left: 1rem !important;
  margin-right: 1rem !important;
}
.mlr-l {
  padding-left: 2rem !important;
  margin-right: 2rem !important;
}
.w-full {
  width: 100%;
}
.ant-layout-footer {
  background: #1C3544;
}
.ant-layout-header {
  color: rgba(0, 0, 0, 0.85);
  background: #00000063;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  opacity: 1;
  transition: opacity 1s;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex {
  flex: 1;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
@media only screen and (min-width: 1024px) {
  .auto-content {
    margin: 0 10%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 1024px) {
  .auto-content {
    margin: 0 5%;
  }
}
@media only screen and (max-width: 400px) {
  .auto-content {
    margin: 0 5%;
  }
}
.admin-login {
  background: #F9FBFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.admin-login .admin-login-body {
  border-radius: 8px;
  background: #fff;
  box-shadow: 5px 5px 10px #cccccc;
  padding: 3rem 3rem 6rem;
  width: 28rem;
}
.admin-login .admin-login-body .admin-logo {
  width: 60%;
  max-width: 12rem;
  height: auto;
  margin: 0 auto;
  display: block;
}
.admin-login .admin-login-body .admin-title {
  text-align: center;
  font-size: 24px;
  letter-spacing: 0.1rem;
}
.admin-wrapper {
  overflow-y: hidden;
}
.admin-wrapper .ant-layout-sider {
  background: #00253c;
  height: 100vh;
}
.admin-wrapper .ant-layout-sider .admin-sider .admin-logo {
  width: 140px;
  height: auto;
  display: block;
  margin: 2rem auto;
}
.admin-wrapper .ant-layout-sider .admin-sider .ant-menu.ant-menu-dark {
  background: none;
}
.admin-wrapper .ant-layout-header {
  height: 40px;
  background: #ffffff;
  line-height: 40px;
  position: initial;
  padding: 0 1rem;
  border-bottom: 1px solid #ebebeb;
}
.admin-wrapper .ant-layout-header .ant-breadcrumb {
  line-height: inherit;
}
.admin-wrapper .admin-home {
  background: #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.admin-wrapper .admin-home .inner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.admin-wrapper .admin-home .inner .logo-img {
  width: 180px;
  height: auto;
  display: block;
}
.admin-wrapper .admin-home .inner .title {
  font-size: 1.5rem;
}
.admin-wrapper .main-wrap {
  height: calc(100% - 40px);
}
.admin-content .admin-head {
  background: #ffffff;
  padding: 1rem 2rem;
}
.admin-content .admin-head .ant-form-item {
  margin-bottom: 0;
}
.admin-content .admin-body {
  margin: 0.8rem 0.8rem 0;
  padding: 0.8rem 0.8rem 0;
  background: #ffffff;
}
.bf-container {
  border: 1px solid #dedede;
}
.admin-sub .admin-body {
  padding: 0.8rem;
  background: #ffffff;
  height: calc(100vh - 90px);
  overflow-y: scroll;
}
.admin-sub .admin-footer {
  background: #ffffff;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ebebeb;
  height: 50px;
  line-height: 50px;
  padding-right: 180px;
}
#root {
  height: 100%;
}
.relative {
  position: relative;
}
.img-responsive {
  width: 100%;
  height: auto;
}
.header {
  display: flex;
  justify-content: space-around;
}
.header .left .home-logo {
  height: 55px;
  width: auto;
  padding-bottom: 15px;
}
.header .left .logo-text {
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0.2rem;
  text-shadow: 0 1px 2px #535353;
  margin-left: 0.5rem;
}
.header .middle .ant-menu-item-selected,
.header .middle .ant-menu-item-active {
  color: #6febff !important;
  border-color: #6febff !important;
}
.header .ant-menu {
  background: transparent;
  color: #fff;
}
.header .ant-menu.ant-menu-horizontal {
  border-bottom: none;
}
.carousel-container {
  height: 560px;
  overflow: hidden;
  position: relative;
}
.carousel-container .carousel-img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}
.core-info-card {
  background: #fff;
  padding: 4rem 0;
  box-shadow: 0 2px 10px #ebebeb;
}
.core-info-card .core-card-title {
  font-size: 1.4rem;
  font-weight: bold;
}
.core-info-card .core-card-description {
  white-space: pre-line;
}
.core-info-card img {
  width: 100px;
  height: auto;
}
.footer {
  padding: 1.5rem;
}
.footer .qr-code {
  flex: 1 0 8rem;
}
.footer .qr-code img {
  width: 100%;
  height: auto;
  border: 4px solid #fff;
}
.footer .ant-descriptions-title {
  color: #cccccc;
}
.footer .ant-descriptions-item-label {
  color: #c7eaff;
}
.footer .ant-descriptions-item-content {
  color: #ffffff;
}
.footer-info {
  text-align: center;
  color: #fff;
  background: #0f222e;
  margin-left: -50px;
  margin-bottom: -24px;
  margin-right: -50px;
  padding: 0.4rem;
}
.advantage-container .advantage-container-body {
  position: relative;
  background: #fff;
  padding: 3rem 1.5rem;
}
.advantage-container .advantage-container-body .advantage-item {
  text-align: center;
}
.advantage-container .advantage-container-body .advantage-item .advantage-item-icon {
  width: 140px;
  height: 140px;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 10px #cccccc;
}
.advantage-container .advantage-container-body .advantage-item .advantage-item-title {
  font-weight: 600;
  font-size: 1.2rem;
  color: #666666;
  margin-top: 1rem;
}
.advantage-container .advantage-container-body .advantage-item .advantage-item-description {
  white-space: pre;
  color: #999999;
  margin-top: 0.5rem;
}
.advantage-bg {
  background: #27B8D0 url("./imgs/home/u57_state0.png") no-repeat center;
  height: 400px;
  width: 100%;
  position: absolute;
  top: 0;
}
.page-header-dark {
  padding-left: 0;
}
.page-header-dark .ant-page-header-heading-title {
  font-size: 2rem;
}
.page-header-light {
  padding-left: 0;
}
.page-header-light .ant-page-header-heading-title {
  color: #fff;
  font-size: 2rem;
}
.page-header-light .ant-page-header-heading-sub-title {
  color: #ffffffba;
}
.home .ant-page-header {
  padding: 2rem 0;
}
.solution-role .solution-role-body {
  background: #fff;
  padding: 3rem 1.5rem;
  text-align: left;
}
.solution-role .solution-role-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #27B8D0;
  font-weight: 500;
}
.solution-role .solution-role-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .title {
  color: #27B8D0;
}
.solution-role .solution-role-body .ant-tabs-tab .ant-tabs-tab-btn {
  color: #666666;
  text-align: left;
}
.solution-role .solution-role-body .ant-tabs-tab .ant-tabs-tab-btn .title {
  font-size: 1.2rem;
  color: #333333;
}
.solution-role .solution-role-body .img-container {
  height: 400px;
}
.darker {
  filter: brightness(0.5);
}
.top-big-image-container {
  position: relative;
}
.top-big-image-container .top-big-img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}
.top-big-image-container .text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: relative;
}
.top-big-image-container .text-container .text-container-inner .title {
  font-size: 2.8rem;
}
.top-big-image-container .text-container .text-container-inner .sub-title {
  font-size: 1.2rem;
  margin-top: 1.5rem;
}
.animation-info-card-container-wrapper:nth-of-type(odd) {
  background: #fff;
}
.animation-info-card-container-wrapper .animation-info-card-container {
  display: flex;
  padding: 3rem 1.5rem;
}
.animation-info-card-container-wrapper .animation-info-card-container .left {
  flex: 1;
}
.animation-info-card-container-wrapper .animation-info-card-container .left .image-container {
  overflow: hidden;
}
.animation-info-card-container-wrapper .animation-info-card-container .left .image-container img {
  width: 100%;
  height: auto;
  transform: translateY(100%);
  transition: all 0.5s;
}
.animation-info-card-container-wrapper .animation-info-card-container .right {
  flex: 1;
  padding: 2.5rem 0 0 2.5rem;
}
.animation-info-card-container-wrapper .animation-info-card-container .right .info-container .title {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 2rem;
}
.animation-info-card-container-wrapper .animation-info-card-container .right .info-container .list {
  font-size: 1rem;
  color: #666666;
}
.animation-info-card-container-wrapper .animation-info-card-container .right .info-container .list .info-list-item {
  margin-top: 0.5rem;
}
.animation-info-card-container-wrapper .animation-info-card-container .right .info-container .list .info-list-item::first-letter {
  content: "";
  display: inline-block;
  border-left: 8px solid #27B8D0;
  padding-left: 0.5rem;
}
.price-bar-item-container {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.price-bar-item-container .price-bar-item-header {
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 222;
  background: #ffffff;
  border-bottom: 2px solid #27B8D0;
}
.price-bar-item-container .price-bar-item-header .inner {
  text-align: center;
}
.price-bar-item-container .price-bar-item-header .inner .title {
  font-size: 2rem;
}
.price-bar-item-container .price-bar-item-header .inner .extra {
  margin-top: 1rem;
}
.price-bar-item-container .divider {
  height: 3rem;
}
.price-bar-item-container .list-container {
  text-align: center;
}
.price-bar-item-container .list-container .list-item {
  height: 3rem;
  line-height: 3rem;
  border-bottom: none;
  padding: 0.2rem 1rem;
  color: #666666;
}
.price-bar-item-container .list-container .list-item .anticon {
  font-size: 1.5rem;
}
.price-bar-item-container .list-container .list-item:nth-of-type(even) {
  background: #F9F9F9;
}
.product-price-body {
  background: #fff;
  top: -6rem;
  box-shadow: 0 5px 10px #cccccc;
  padding: 3rem;
  position: relative;
  margin-bottom: -3rem;
}
.product-price-body .divider-wrapper {
  background: #DFF7FB;
  position: absolute;
  top: 10rem;
  width: 100%;
  z-index: 111;
}
.product-price-body .divider-wrapper .divider-img img {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 100%;
}
.product-price-body .divider-wrapper .divider-label {
  font-size: 1.2rem;
  font-weight: bold;
}
.solution .ant-affix {
  z-index: 6666;
  top: 60px !important;
}
.solution .ant-anchor-wrapper {
  background: none;
}
.solution .ant-anchor-wrapper .ant-anchor > .ant-anchor-link > .ant-anchor-link-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #666666;
}
.solution .ant-anchor-wrapper .ant-anchor .ant-anchor-link .ant-anchor-link {
  padding-top: 8px;
  padding-bottom: 8px;
}
.solution .ant-anchor-wrapper .ant-anchor-ink::before {
  background-color: #ebebeb;
}
.solution .view-container {
  background: #fff;
  padding: 3rem;
  box-shadow: 0 5px 10px #cccccc;
  color: #444444;
}
.solution .view-container .title-lg {
  font-size: 1.4rem;
  font-weight: bold;
}
.solution .view-container .title-lg::before {
  content: "";
  border-left: 6px solid #27B8D0;
  padding-left: 0.5rem;
}
.solution .view-container .title-md {
  font-size: 1.2rem;
  position: relative;
  padding: 1rem 0;
}
.solution .view-container .title-md .sn {
  font-size: 4rem;
  font-weight: bold;
  color: #27B8D0;
  position: absolute;
  left: -0.5rem;
  top: -1.2rem;
  opacity: 0.3;
  font-family: fantasy;
  z-index: 10;
}
.solution .view-container .title-md .title-text {
  position: relative;
  z-index: 20;
}
.solution .view-container .text {
  color: #666666;
}
.solution .view-container img {
  margin-bottom: 3rem;
}
.solution .news-avatar {
  width: 16rem;
  height: 12rem;
}
.news .news-body {
  background: #ffffff;
  padding: 1rem;
  position: relative;
  top: -10rem;
  margin-bottom: -10rem;
}
.news .news-body .ant-list-item .ant-list-item-extra {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}
.news .news-body .ant-list-item .ant-list-item-extra h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #cccccc;
}
.news .news-body .ant-list-item .ant-list-item-meta-avatar {
  flex: 1 0 400px;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.news .news-body .ant-list-item .ant-list-item-meta-avatar .news-avatar {
  position: absolute;
  bottom: 0;
  height: 100%;
}
.news .news-body .ant-list-item .ant-list-item-meta-avatar .news-avatar img {
  height: 100%;
  width: auto;
}
.news .news-body .ant-list-item .ant-list-item-meta {
  height: 240px;
  margin-bottom: 0;
}
.news .news-body .ant-list-item .ant-list-item-meta-content {
  flex: 1 0 calc(100% - 400px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news .news-body .ant-list-item .ant-list-item-meta-content .ant-list-item-meta-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1.5rem 0;
}
.news .news-body .ant-list-item .ant-list-item-meta-content .ant-list-item-meta-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 0.5rem;
}
.about-us .about-us-body {
  padding: 2rem 4rem;
  box-shadow: 0 5px 10px #cccccc;
  margin: 4rem 4rem 0 4rem;
  position: relative;
  top: -10rem;
  background: #ffffff;
}
.back-top-pre-item {
  position: fixed;
  bottom: 90px;
  right: 0;
  z-index: 6666;
}
.ant-back-top {
  right: 0;
  width: 40px;
  height: 40px;
}
.back-top-grid {
  width: 40px;
  height: 40px;
  background: #00000061;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-top-grid:hover {
  cursor: pointer;
  background: #00000099;
}
.back-top-grid .anticon svg {
  font-size: 1.5rem;
  color: #fff;
}
.article_wrapper {
  background: #fff;
  padding: 3rem 0;
}
.article_wrapper .article_content .title {
  font-size: 2.4rem;
  font-weight: 600;
}
.article_wrapper .article_content .sub-title {
  padding: 1rem 1rem 1rem 2.5rem;
  background: #ebebeb;
  border-radius: 4px;
  font-size: 1.1rem;
  color: #999999;
  position: relative;
}
.article_wrapper .article_content .sub-title::before {
  content: "‘‘";
  font-size: 2rem;
  font-family: cursive;
  position: absolute;
  letter-spacing: -1.5rem;
  left: 0;
  top: 0.5rem;
}
.hot-article {
  border: 1px solid #ebebeb;
}
.hot-article .ant-list-header {
  font-size: 1.2rem;
  padding: 1rem 4rem;
}
.hot-article .ant-list-item {
  padding: 1rem;
  border: 0;
}
.hot-article .hot-article .hot-number {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  color: #999999;
  background: #ebebeb;
  line-height: 32px;
}
.hot-article .hot-article .hot-number.red {
  color: #ff5722;
  background: #fbe9e7;
}
